import { dev } from '$app/environment'
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

if (!dev)
	Sentry.init({
		dsn: 'https://3751f126e6227eab8d3df6575023b806@o4507642257735680.ingest.us.sentry.io/4507642261471232',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			replayIntegration(),
			Sentry.captureConsoleIntegration({ levels: ['error'] }),
			Sentry.httpClientIntegration({ failedRequestStatusCodes: [400, 599] }),
			// Sentry.browserProfilingIntegration(),
		],
		sendDefaultPii: true,
		// profilesSampleRate: 1.0,
	})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
