import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [3];

export const dictionary = {
		"/(landing)": [13,[4]],
		"/(app)/app": [5,[2,3]],
		"/(app)/change-email-end": [~6,[2]],
		"/(app)/change-password-end": [~7,[2]],
		"/(app)/confirm-email": [~8,[2]],
		"/(app)/login": [~9,[2]],
		"/(landing)/privacy-policy": [14,[4]],
		"/(app)/recover-password-end": [~10,[2]],
		"/(app)/recover-password-send": [11,[2]],
		"/(app)/register": [12,[2]],
		"/sentry-example": [16],
		"/(landing)/terms-and-conditions": [15,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';